import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
import SymbolSvg from "../assets/images/site-branding/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const BlockJobs = makeShortcode("BlockJobs");
const AnimateIntroRow = makeShortcode("AnimateIntroRow");
const Decoration = makeShortcode("Decoration");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimateIntroBanner id="banner" className="background_logo light" background={<><RowLines mdxType="RowLines" /><SymbolSvg mdxType="SymbolSvg" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Careers
    </Heading>
  </Block>
  <Block className="content_block" mdxType="Block">
    <Paragraph mdxType="Paragraph">At Hausman, we're constantly on the lookout for skilled professionals who share our vision, values, and enthusiasm for functionality and efficiency.</Paragraph>
  </Block>  
    </AnimateIntroBanner>
    <Row id="jobs" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <BlockJobs mdxType="BlockJobs" />
    </Row>
    <AnimateIntroRow id="take_part" background={<RowLines mdxType="RowLines" />} className="parallax_wrapper" mdxType="AnimateIntroRow">
  <Block mdxType="Block">
    <Heading className="no_animation" mdxType="Heading">
      <div className="intro_from_left">Join our</div>
      <div className="intro_from_left">team of experts.</div>
    </Heading>
    <Decoration from="/careers/take-part.jpg" className="parallax" mdxType="Decoration" />
  </Block>
    </AnimateIntroRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      